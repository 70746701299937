import { operation, resources } from '@utils/enums/permissions-enums';

export namespace PermissionsList {
  export class DevicePermissions {
    private static entity = resources.device;
    public static CreateDevice = `${operation.create}.${this.entity}`;
    public static ReadDevice = `${operation.read}.${this.entity}`;
    public static UpdateDevice = `${operation.update}.${this.entity}`;
    public static DeleteDevice = `${operation.delete}.${this.entity}`;
  }

  export class DeviceNotesPermissions {
    private static entity = resources.note;
    public static CreateDeviceNote = `${operation.create}.${this.entity}`;
    public static ReadDeviceNote = `${operation.read}.${this.entity}`;
    public static UpdateDeviceNote = `${operation.update}.${this.entity}`;
    public static DeleteDeviceNote = `${operation.delete}.${this.entity}`;
  }

  export class AlarmPermissions {
    private static entity = resources.alarm;
    public static ReadAlarm = `${operation.read}.${this.entity}`;
    public static UpdateAlarm = `${operation.update}.${this.entity}`;
    public static DeleteAlarm = `${operation.delete}.${this.entity}`;
  }
}
