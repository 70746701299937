import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from '@services/app-config.service';
import { Observable } from 'rxjs';

const MEDIA_TYPE = 'application/vnd.api+json';

const typeNameByRelName: Record<string, string> = {
  organization: 'organizations',
  users: 'users',
  permissions: 'permissions',
  user_roles: 'user_roles',
};

export interface RoleAttrs {
  name: string;
}

export interface RoleRels {
  organization: { data: ResourceIdentifierObject };
  users: { data: ResourceIdentifierObject[] };
  permissions: { data: ResourceIdentifierObject[] };
  user_roles: { data: ResourceIdentifierObject[] };
}

export interface Role<Attrs = Partial<RoleAttrs>, Rels = Partial<RoleRels>> {
  type: string;
  id: string;
  attributes?: Attrs;
  relationships?: Rels;
}

export interface ResourceIdentifierObject {
  type: string;
  id: string;
}

export interface RoleData {
  data: Role;
  included?: ResourceIdentifierObject[];
}

export interface MultiRoleData {
  data: Role[];
  included?: ResourceIdentifierObject[];
}

@Injectable({
  providedIn: 'root',
})
export class RoleService {
  uiBaseURL = '';

  constructor(
    private http: HttpClient,
    appConfigService: AppConfigService,
  ) {
    this.uiBaseURL = appConfigService.getJsonApiUrl();
  }

  getMulti(params: HttpParams): Observable<MultiRoleData> {
    const url = `${this.uiBaseURL}/roles`;
    const options = {
      headers: new HttpHeaders({
        'Content-Type': MEDIA_TYPE,
      }),
      params: params,
    };
    return this.http.get<MultiRoleData>(url, options);
  }
}
